import "antd/dist/antd.css"; // make sure antd is first import in index.css otherwise the ui will be affected
import "flag-icons/css/flag-icons.min.css";
import "@eqengage/design-system-v2/dist/bundle.css";
import "@eqengage/design-system-v2/dist/components.css";
import * as FullStory from "@fullstory/browser";
import ReactDOM from "react-dom/client";
import "./index.css";
import './assets/fonts/font-poppins.css';

import reportWebVitals from "./reportWebVitals";
import { initI18n } from "./i18n";
import React from "react";



const App = React.lazy(() => import("./App"));
fetch(`${window.location.origin}/runtime-env.js`)
  .then((res) => {
    return res.json();
  })
  .then((res) => {
    window.__RUNTIME_CONFIG__ = JSON.parse(res);
  })
  .then(() => {
    initI18n().then(() => {
      startReact();
    });
  })
  .catch(() => console.error("Failed to set runtime config"));

async function startReact() {
  const FULL_STORY_ORGANIZATION_ID =
    window.__RUNTIME_CONFIG__.REACT_APP_FULL_STORY_ORGANIZATION_ID ?? undefined;
  if (FULL_STORY_ORGANIZATION_ID) {
    FullStory.init({
      orgId: FULL_STORY_ORGANIZATION_ID,
    });
  }
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(<App />);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
