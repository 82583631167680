import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getDcaLanguages } from "./api/requests";
import { getDefaultDca } from "./utils/dca";

export const initI18n = async () => {
  const dcaId = getDefaultDca();
  console.log("im being called=====================================================1")
  const dcaLanguages = await getDcaLanguages(dcaId);
  console.log("im being called=====================================================2")
  // language to be displayed on page load
  const initialLang =
    localStorage.getItem("selectedLng") ??
    dcaLanguages.find((l) => l.isDefault)?.lang ??
    "en-us";

  const supportedNamespaces = [
    "engage-payment-portal",
    "content-ia-subheader",
    "content-ia-ctatext",
    "content-ia-header-banner",
    "content-ia-sub-title-banner",
    "content-ia-ctatext-banner",
    "content-ia-header",
    "content-portalsettings-header",
    "content-portalsettings-cta",
    "content-portal",
  ];

  return i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      fallbackLng: initialLang,
      lowerCaseLng: true,
      supportedLngs: dcaLanguages.map((l) => l.lang),
      ns: supportedNamespaces,
      defaultNS: "engage-payment-portal",
      backend: {
        loadPath: `${window.__RUNTIME_CONFIG__.URL_LINGUAL_SERVICE}/texts/{{lng}}/{{ns}}`,
        queryStringParams: { dcaId },
      },
    });
};
